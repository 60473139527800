import { configureStore } from '@reduxjs/toolkit'

import jwtReducer from './features/jwt/jwtSlice'
import currentUserReducer from './features/currentUser/currentUserSlice'
import globalConfigurationReducer from './features/globalConfiguration/globalConfigurationSlice'
import sidebarUnfoldableReducer from './features/sidebarUnfoldable/sidebarUnfoldableSlice'
import sidebarShowReducer from './features/sidebarShow/sidebarShowSlice'
import activeBusinessEntityReducer from './features/activeBusinessEntity/activeBusinessEntitySlice'
import prefillFormDataReducer from './features/prefillFormData/prefillFormDataSlice'

export const store = configureStore({
  reducer: {
    jwt: jwtReducer,
    currentUser: currentUserReducer,
    globalConfiguration: globalConfigurationReducer,
    sidebarUnfoldable: sidebarUnfoldableReducer,
    sidebarShow: sidebarShowReducer,
    activeBusinessEntity: activeBusinessEntityReducer,
    prefillFormData: prefillFormDataReducer,
  },
})
